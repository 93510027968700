.Main {
    flex-direction: row;
}

/* 
.MainImageContainer {
    max-width: 100%;
    width: 50%;
} */
.MainImage {
    position: relative
}

/* .Text {
    max-width: 50%;
    padding-left: 2rem;
} */

@media screen and (max-width: 720px) {
    /* .MainImageContainer {
        width: 100%;
    }

    .Text {
        max-width: 100%;
        padding-left: 0;
        padding-top: 1rem
    } */
}